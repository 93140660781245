<template>
    <el-table v-show="tableData.length > 0" v-loading="$wait.is('loading.addresses')" :data="tableData" class="mb-4" element-loading-spinner="el-custom-spinner">
        <el-table-column :label="$t('clients.title')" prop="title" sortable :show-overflow-tooltip="true" />
        <el-table-column :label="$t('clients.street')" prop="street" sortable />
        <el-table-column :label="$t('clients.city')" prop="city" sortable />
        <el-table-column :label="$t('clients.post_code')" prop="post_code" sortable />
        <el-table-column v-if="userIsBuildingCompany()" :label="$t('clients.status')" prop="status" sortable>
            <template slot-scope="scope">
                <p v-if="scope.row.status">
                    {{ $t(`clients.${scope.row.status}`) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column width="70">
            <template slot-scope="scope">
                <el-button-group class="flex">
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="left">
                        <router-link :to="`/clients/${clientUuid}/addresses/${scope.row.uuid}`">
                            <el-button type="primary" size="mini">
                                <fa-icon :icon="['fas', 'info']" fixed-width />
                            </el-button>
                        </router-link>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('clients.delete')" placement="top">
                        <el-button type="danger" size="mini" :disabled="tableData.length === 1" @click="handleDelete(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        tableData: {
            type:    Array,
            default: () => [],
        },
    },

    data() {
        return {
            clientUuid: this.$route.params.uuid,
        };
    },

    methods: {
        handleDelete(uuid) {
            this.$emit('handleDelete', uuid);
        },
    },
};
</script>
